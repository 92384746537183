<template>
  <v-dialog v-model="passDialog" persistent max-width="400">
    <v-card>
      <v-card-title>change password</v-card-title>

      <v-card-text>
        <v-text-field
          v-model="changes.current_password"
          label="current password"
          :type="showCurrent ? 'text' : 'password'"
          :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showCurrent = !showCurrent"
        ></v-text-field>
        <v-text-field
          v-model="changes.new_password"
          counter="7"
          label="new password"
          :type="showNew ? 'text' : 'password'"
          :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showNew = !showNew"
        ></v-text-field>
        <v-text-field
          v-model="changes.confirm_new_password"
          counter="7"
          :hint="retypeHint"
          label="confirm new password"
          :type="showConfirm ? 'text' : 'password'"
          :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showConfirm = !showConfirm"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-btn text color="red" small @click="cancel"> cancel </v-btn>
        <v-btn
          text
          color="primary"
          small
          @click="submit"
          :disabled="$v.changes.$invalid"
        >
          submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    passDialog: Boolean,
  },
  // mixins: [Utils],
  data() {
    return {
      show1: false,
      showCurrent: false,
      showNew: false,
      showConfirm: false,
      responseMessage: null,
      retypeHint: "Retype to confirm the new new password.",
      changes: {
        current_password: null,
        new_password: null,
        confirm_new_password: null,
      },
    };
  },
  validations: {
    changes: {
      current_password: {
        required,
        minLength: minLength(7),
      },
      new_password: {
        required,
        minLength: minLength(7),
      },
      confirm_new_password: {
        required,
        minLength: minLength(7),
        sameAs: sameAs("new_password"),
      },
    },
  },
  methods: {
    cancel() {
      let payload = { state: false };
      this.$emit("closePass", payload);
    },
    submit() {
      let password = this.changes.current_password;
      let newPassword = this.changes.confirm_new_password;
      let data = { newPassword, password };
      let payload = { state: false, data: data };
      this.$emit("submitPass", payload);
    },
  },
};
</script>
