<template>
  <div class="mypage">
    <v-app>
      <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        app
        class="navibar"
        color="brown"
        dark
      >
        <v-toolbar color="#226900" dense flat>
          <v-avatar color="#2a7d00" size="30">
            <v-img
              :src="require('../../assets/images/favicon.png')"
              contain
              height="20"
            ></v-img>
          </v-avatar>
          <div style="margin-left: 20px">
            <v-img
              :lazy-src="require('../../assets/images/logo_light.png')"
              :src="require('../../assets/images/logo_light.png')"
              contain
              width="90"
            ></v-img>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <div v-if="mini === true">
          <v-avatar color="white">t</v-avatar>
        </div>
        <div v-else>
          <div class="profilia">
            <v-btn class="myimg" icon x-large @mouseout="checkPix">
              <v-avatar>
                <v-img :src="myPix"></v-img>
              </v-avatar>
            </v-btn>
            <div class="myself">Hi, {{ userName | capitalize }}</div>

            <div class="userlinks">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    color="white"
                    fab
                    icon
                    v-on="on"
                    @click.stop="passDialog = true"
                  >
                    <v-icon>mdi-key</v-icon>
                  </v-btn>
                </template>
                <span>Change Password</span>
              </v-tooltip>
              <PasswordChange
                :passDialog="passDialog"
                @closePass="closePass"
                @submitPass="submitPass"
              />

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="{ name: 'medics.account' }"
                    color="white"
                    fab
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit Profile</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn color="white" fab icon @click="logout" v-on="on">
                    <v-icon>mdi-power-standby</v-icon>
                  </v-btn>
                </template>
                <span>Logout</span>
              </v-tooltip>
            </div>
          </div>
        </div>

        <div class="navside">
          <v-list dense flat>
            <v-list-item
              v-for="a in topLinks"
              :key="a.title"
              :to="a.links"
              ripple
              router
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-list-item-icon>
                    <v-icon>{{ a.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content v-on="on">
                    <v-list-item-title>{{ a.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <span>{{ a.caption }}</span>
              </v-tooltip>
            </v-list-item>
          </v-list>

          <v-list dense>
            <v-list-group
              v-for="item in items"
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="child in item.items"
                :key="child.title"
                :to="child.links"
                router
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </v-navigation-drawer>

      <v-main id="route_content" class="main_content">
        <transition mode="out-in" name="fade">
          <router-view @sidebar="sidebar"></router-view>
        </transition>
      </v-main>
    </v-app>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils";
import PasswordChange from "@/components/accounts/myuser/change_password.vue";
// import ProfilePix from "@/components/accounts/myuser/picture.vue";
export default {
  mixins: [Utils],
  components: {
    PasswordChange,
    // ProfilePix,
  },
  data() {
    return {
      passDialog: false,
      drawer: null,
      userName: null,
      mini: false,
      myPix: null,
      topLinks: [
        {
          icon: "mdi-view-dashboard",
          title: "dashboard",
          caption: "Dashboard",
          links: { name: "medics.dash" },
        },
        {
          icon: "mdi-account-group",
          title: "my clients",
          caption:
            "Search for clients already registered on Umohe or add a new client, or their dependents",
          links: { name: "medics.client" },
        },
      ],
      items: [
        {
          icon: "mdi-toolbox-outline",
          active: false,
          title: "services",
          items: [
            { title: "my services ", links: { name: "medics.services" } },
          ],
        },
        {
          icon: "mdi-calendar-cursor",
          active: false,
          title: "Consultations",
          items: [
            { title: "Schedule Plan", links: { name: "medics.schedule" } },
            // { title: "Clients", links: { name: "medics.client" } },
            { title: "Appointments", links: { name: "medics.appointment" } },
          ],
        },
        {
          icon: "fa-user-md",
          active: false,
          title: "Treatments",
          items: [
            // {
            //   title: "Patient Treatment",
            //   links: { name: "medics.treatment" },
            // },
            {
              title: "Treatments History",
              links: { name: "medics.histreat" },
            },
          ],
        },
        {
          icon: "mdi-face-agent",
          active: false,
          title: "Reviews",
          items: [
            { title: "Clients Reviews", links: { name: "medics.reviews" } },
          ],
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
    };
  },
  created() {
    this.userName = localStorage.getItem("user.name");
    this.userPix();
  },
  methods: {
    closePass(p) {
      this.passDialog = p.state;
    },
    submitPass(p) {
      this.snack.bar = true;
      // console.log(p);
      // console.log(p.data);
      let self = this;
      Restful.accounts
        .updatePassword(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.passDialog = p.state;
    },
    sidebar() {
      // console.log(this.drawer);
      console.log();
      if (this.drawer === true) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }
    },
    userPix() {
      let pix = localStorage.getItem("user.pix");
      if (pix === "") {
        this.myPix = require("../../assets/images/my_user.png");
      } else {
        this.myPix = pix;
      }
    },
    checkPix(e) {
      console.log(e);
      this.myPix = localStorage.getItem("user.pix");
    },
    logout() {
      localStorage.clear();
      this.$router.push({
        name: "home",
      });
    },
  },
};
</script>
